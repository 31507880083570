import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import {
  IdType,
  Maybe,
  ObjectWithoutType,
  ObjectWithType,
  Pagination,
  Query,
} from 'onescreen/types';
import { CCA, ConnectionLevel, Rate, RateType, Source } from '.';

import { ServiceAgreement } from '../assets';
import { BaseAPI } from '../base';
import { BundledRatePlan } from './bundled_rate_plan';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'CCARatePlan'> & {
  friendly_name: string;
  id: IdType;
  rates?: Array<Rate['id']>;
  change_dates?: null;
  service_agreements: Array<ServiceAgreement['id']>;
  bundled_rate_plan: BundledRatePlan['id'];
  cca: CCA['id'];
};

export declare namespace CCARatePlan {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  export namespace API {
    export type SideloadedResponses = {
      ccas?: CCA.Raw[];
      bundled_rate_plans?: BundledRatePlan.Raw[];
      connection_levels?: ConnectionLevel.Raw[];
      rates?: Rate.Raw[];
      rate_types?: RateType.Raw[];
      sources?: Source.Raw[];
    };

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResult = CCARatePlan;
    export type RetrieveResponse = SideloadedResponses & { cca_rate_plan: CCARatePlan.Raw };

    export type ListParams = Query.DynamicRestParams & Query.PaginationParams;
    export type ListResult = Pagination.Response.Parsed<CCARatePlan>;
    export type ListResponse = Pagination.Response.Raw<
      SideloadedResponses & { cca_rate_plans: CCARatePlan.Raw[] }
    >;
  }
}

/** ======================== API =========================================== */
class CCARatePlanAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.ccaRatePlans;

  static async retrieve(
    id: CCARatePlan['id'],
    params?: CCARatePlan.API.RetrieveParams
  ): Promise<CCARatePlan.API.RetrieveResult> {
    const { json } = await this.get<CCARatePlan.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const { connection_levels, rates, rate_types, sources } = this.parseSideloadedObjects(json);
    const cca_rate_plan = CCARatePlan.fromObject(json.cca_rate_plan);

    store.dispatch(
      slices.data.updateModels(cca_rate_plan, connection_levels, rates, rate_types, sources)
    );
    return cca_rate_plan;
  }

  static async list(params?: CCARatePlan.API.ListParams): Promise<CCARatePlan.API.ListResult> {
    const { json } = await this.get<CCARatePlan.API.ListResponse>(this.route, params);

    // Parse response
    const { ccas, bundled_rate_plans } = this.parseSideloadedObjects(json.results);
    const cca_rate_plans = this.parsePaginationSet(json, ({ cca_rate_plans }) =>
      CCARatePlan.fromObjects(cca_rate_plans)
    );

    store.dispatch(slices.data.updateModels(cca_rate_plans.results, ccas, bundled_rate_plans));
    return cca_rate_plans;
  }

  static parseSideloadedObjects(json: CCARatePlan.API.SideloadedResponses) {
    return {
      ccas: CCA.fromObjects(json.ccas),
      bundled_rate_plans: BundledRatePlan.fromObjects(json.bundled_rate_plans),
      connection_levels: ConnectionLevel.fromObjects(json.connection_levels),
      rates: Rate.fromObjects(json.rates),
      rate_types: RateType.fromObjects(json.rate_types),
      sources: Source.fromObjects(json.sources),
    };
  }
}

/** ======================== Model ========================================= */
export interface CCARatePlan extends CommonAttrs {}
export class CCARatePlan {
  /** ====================== Static fields ================================= */
  static api = CCARatePlanAPI;
  static readonly rawFields = [
    'change_dates',
    'bundled_rate_plan',
    'friendly_name',
    'id',
    'rates',
    'service_agreements',
    'cca',
  ] as const;

  /**
   * Parses an array of raw CCARatePlan objects by passing each in turn to
   * CCARatePlan.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {CCARatePlan.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: CCARatePlan.Raw[]): CCARatePlan[];
  static fromObjects(raw: Maybe<CCARatePlan.Raw[]>): Maybe<CCARatePlan[]>;
  static fromObjects(raw: Maybe<CCARatePlan.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: CCARatePlan.Raw): CCARatePlan;
  static fromObject(raw: Maybe<CCARatePlan.Raw>): Maybe<CCARatePlan>;
  static fromObject(raw: Maybe<CCARatePlan.Raw>) {
    return raw ? new CCARatePlan(raw) : undefined;
  }

  static fromStore(): CCARatePlan[];
  static fromStore(id: Maybe<CCARatePlan['id']>): Maybe<CCARatePlan>;
  static fromStore(id?: CCARatePlan['id']) {
    const { ccaRatePlans } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(ccaRatePlans)).map(CCARatePlan.fromObject);
    } else {
      return id ? CCARatePlan.fromObject(ccaRatePlans[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'CCARatePlan';
  constructor(raw: CCARatePlan.Raw) {
    Object.assign(this, _.pick(raw, CCARatePlan.rawFields));
  }

  get Rates() {
    return this.rates?.map(Rate.fromStore) || [];
  }

  get ServiceAgreements() {
    return this.service_agreements.map(ServiceAgreement.fromStore) || [];
  }

  get CCA() {
    return CCA.fromStore(this.cca);
  }

  serialize(): CCARatePlan.Serialized {
    return { ..._.pick(this, CCARatePlan.rawFields), object_type: this.object_type };
  }
}
