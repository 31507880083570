import _ from 'lodash';

import { slices, store } from 'onescreen/store';
import {
  IdType,
  Maybe,
  ObjectWithoutType,
  ObjectWithType,
  Pagination,
  Query,
} from 'onescreen/types';
import {
  BillingUnit,
  CCA,
  Rate,
  RateType,
  RegulatorRatePlan,
  Source,
  Utility,
  UtilityRatePlan,
} from '.';
import { Bucket, Season } from '..';

import { ServiceAgreement } from '../assets';
import { BaseAPI } from '../base';
import { CCARatePlan } from './cca_rate_plan';
import { ConnectionLevel } from './connection_level';
import { CustomerClassRatePlan } from './customer_class_rate_plan';

/** ======================== Types ========================================= */
type CommonAttrs = ObjectWithType<'BundledRatePlan'> & {
  friendly_name: string;
  id: IdType;
  rates?: Array<Rate['id']>;
  change_dates?: null;
  service_agreements: Array<ServiceAgreement['id']>;
  utility: string;
  connection_levels: Array<ConnectionLevel['id']>;
  cca_rate_plans: Array<CCARatePlan['id']>;
  customer_class_rate_plan: CustomerClassRatePlan['id'];
};

export declare namespace BundledRatePlan {
  // Related types
  export interface Raw extends ObjectWithoutType<Serialized> {}
  export interface Serialized extends CommonAttrs {}

  export namespace API {
    export type SideloadedResponses = {
      connection_levels?: ConnectionLevel.Raw[];
      customer_class_rate_plans?: CustomerClassRatePlan.Raw[];
      utility_rate_plans?: UtilityRatePlan.Raw[];
      regulator_rate_plans?: RegulatorRatePlan.Raw[];
      utilities?: Utility.Raw[];
      rates?: Rate.Raw[];
      rate_types?: RateType.Raw[];
      sources?: Source.Raw[];
      buckets?: Bucket.Raw[];
      seasons?: Season.Raw[];
      billing_units?: BillingUnit.Raw[];
      service_agreements?: ServiceAgreement.Raw[];
      cca_rate_plans?: CCARatePlan.Raw[];
      ccas?: CCA.Raw[];
    };

    export type RetrieveParams = Query.DynamicRestParams;
    export type RetrieveResult = BundledRatePlan;
    export type RetrieveResponse = SideloadedResponses & { bundled_rate_plan: BundledRatePlan.Raw };

    export type ListParams = Query.DynamicRestParams & Query.PaginationParams;
    export type ListResult = Pagination.Response.Parsed<BundledRatePlan>;
    export type ListResponse = Pagination.Response.Raw<
      SideloadedResponses & { bundled_rate_plans: BundledRatePlan.Raw[] }
    >;
  }
}

/** ======================== API =========================================== */
class BundledRatePlanAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.ratePlans;

  static async retrieve(
    id: BundledRatePlan['id'],
    params?: BundledRatePlan.API.RetrieveParams
  ): Promise<BundledRatePlan.API.RetrieveResult> {
    const { json } = await this.get<BundledRatePlan.API.RetrieveResponse>(this.route(id), params);

    // Parse response
    const {
      connection_levels,
      customer_class_rate_plans,
      utility_rate_plans,
      regulator_rate_plans,
      rates,
      rate_types,
      sources,
      seasons,
      buckets,
      billing_units,
      service_agreements,
    } = this.parseSideloadedObjects(json);
    const bundled_rate_plan = BundledRatePlan.fromObject(json.bundled_rate_plan);

    store.dispatch(
      slices.data.updateModels(
        bundled_rate_plan,
        connection_levels,
        customer_class_rate_plans,
        utility_rate_plans,
        regulator_rate_plans,
        rates,
        rate_types,
        sources,
        seasons,
        buckets,
        billing_units,
        service_agreements
      )
    );
    return bundled_rate_plan;
  }

  static async list(
    params?: BundledRatePlan.API.ListParams
  ): Promise<BundledRatePlan.API.ListResult> {
    const { json } = await this.get<BundledRatePlan.API.ListResponse>(this.route, params);

    // Parse response
    const {
      ccas,
      connection_levels,
      customer_class_rate_plans,
      cca_rate_plans,
      utility_rate_plans,
      regulator_rate_plans,
      utilities,
    } = this.parseSideloadedObjects(json.results);
    const bundled_rate_plans = this.parsePaginationSet(json, ({ bundled_rate_plans }) =>
      BundledRatePlan.fromObjects(bundled_rate_plans)
    );

    store.dispatch(
      slices.data.updateModels(
        ccas,
        bundled_rate_plans.results,
        connection_levels,
        customer_class_rate_plans,
        cca_rate_plans,
        utility_rate_plans,
        regulator_rate_plans,
        utilities
      )
    );
    return bundled_rate_plans;
  }

  static parseSideloadedObjects(json: BundledRatePlan.API.SideloadedResponses) {
    return {
      connection_levels: ConnectionLevel.fromObjects(json.connection_levels),
      customer_class_rate_plans: CustomerClassRatePlan.fromObjects(json.customer_class_rate_plans),
      utility_rate_plans: UtilityRatePlan.fromObjects(json.utility_rate_plans),
      regulator_rate_plans: RegulatorRatePlan.fromObjects(json.regulator_rate_plans),
      utilities: Utility.fromObjects(json.utilities),
      rates: Rate.fromObjects(json.rates),
      rate_types: RateType.fromObjects(json.rate_types),
      sources: Source.fromObjects(json.sources),
      seasons: Season.fromObjects(json.seasons),
      buckets: Bucket.fromObjects(json.buckets),
      billing_units: BillingUnit.fromObjects(json.billing_units),
      service_agreements: ServiceAgreement.fromObjects(json.service_agreements),
      cca_rate_plans: CCARatePlan.fromObjects(json.cca_rate_plans),
      ccas: CCA.fromObjects(json.ccas),
    };
  }
}

/** ======================== Model ========================================= */
export interface BundledRatePlan extends CommonAttrs {}
export class BundledRatePlan {
  /** ====================== Static fields ================================= */
  static api = BundledRatePlanAPI;
  static readonly rawFields = [
    'change_dates',
    'customer_class_rate_plan',
    'friendly_name',
    'id',
    'rates',
    'service_agreements',
    'utility',
    'connection_levels',
    'cca_rate_plans',
  ] as const;

  /**
   * Parses an array of raw BundledRatePlan objects by passing each in turn to
   * BundledRatePlan.fromObject. Note that calling `raw.map(this.fromObject)` does not work,
   * because TypeScript is unable to resolve the correct overload for fromObject when it is passed
   * to the `map` method. This is a TypeScript design limitation.
   *
   * See https://github.com/microsoft/TypeScript/issues/30369#issuecomment-476402214 for more.
   *
   * @param {BundledRatePlan.Raw[]} [raw]: the array of raw objects to parse
   */
  static fromObjects(raw: BundledRatePlan.Raw[]): BundledRatePlan[];
  static fromObjects(raw: Maybe<BundledRatePlan.Raw[]>): Maybe<BundledRatePlan[]>;
  static fromObjects(raw: Maybe<BundledRatePlan.Raw[]>) {
    return raw ? raw.map((obj) => this.fromObject(obj)) : undefined;
  }

  static fromObject(raw: BundledRatePlan.Raw): BundledRatePlan;
  static fromObject(raw: Maybe<BundledRatePlan.Raw>): Maybe<BundledRatePlan>;
  static fromObject(raw: Maybe<BundledRatePlan.Raw>) {
    return raw ? new BundledRatePlan(raw) : undefined;
  }

  static fromStore(): BundledRatePlan[];
  static fromStore(id: Maybe<BundledRatePlan['id']>): Maybe<BundledRatePlan>;
  static fromStore(id?: BundledRatePlan['id']) {
    const { bundledRatePlans } = store.getState().data;
    if (arguments.length === 0) {
      return _.truthy(Object.values(bundledRatePlans)).map(BundledRatePlan.fromObject);
    } else {
      return id ? BundledRatePlan.fromObject(bundledRatePlans[id]) : undefined;
    }
  }

  /** ====================== Instance fields =============================== */
  readonly object_type = 'BundledRatePlan';
  constructor(raw: BundledRatePlan.Raw) {
    Object.assign(this, _.pick(raw, BundledRatePlan.rawFields));
  }

  get ConnectionLevels() {
    return this.connection_levels.map(ConnectionLevel.fromStore);
  }

  get Rates() {
    return this.rates?.map(Rate.fromStore) || [];
  }

  get CustomerClassRatePlan() {
    return CustomerClassRatePlan.fromStore(this.customer_class_rate_plan);
  }

  get CCARatePlans() {
    return this.cca_rate_plans.map(CCARatePlan.fromStore);
  }

  get ServiceAgreements() {
    return this.service_agreements.map(ServiceAgreement.fromStore) || [];
  }

  serialize(): BundledRatePlan.Serialized {
    return { ..._.pick(this, BundledRatePlan.rawFields), object_type: this.object_type };
  }
}
