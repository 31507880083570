import _ from 'lodash';
import * as React from 'react';

import {
  Box,
  Card,
  Grid,
  List,
  FabMenu,
  Progress,
  Select,
  Typography,
  Dialog,
  Icon,
  Button,
  DownloadLink,
  Tooltip,
} from 'onescreen/components';
import {
  useCostSavings,
  useDialogState,
  useLocalStorageState,
  usePortfolio,
  usePortfolios,
  useServiceAnalysis,
} from 'onescreen/hooks';
import { AMSPortfolio, CostSavings, ServiceAnalysis } from 'onescreen/models';
import { ErrorMessage, Maybe } from 'onescreen/types';

import { DataPanel } from './DataPanel';
import {
  CreateOrganizationSavingsDialog,
  CreateServiceDropAnalysisDialog,
  CreatePortfolioDialog,
  IntervalGapReportDialog,
  BillGapReportDialog,
  CollectDefaultPortfolioDataDialog,
  CollectDefaultOrgDataDialog,
} from './Dialogs';
import { ServiceAgreementTabs } from './ServiceAgreementTabs';
import { Link } from 'react-router-dom';
import { formatters } from '../../common/utils';
import { Divider } from '@material-ui/core';

/** ======================== Types ========================================= */
type PortfolioSelectProps = {
  onChange: (portfolio: AMSPortfolio) => void;
  portfolios?: AMSPortfolio[];
  selection?: AMSPortfolio['id'];
};

type OrgListProps = {
  activeCostSavings: Maybe<CostSavings>;
  portfolio: AMSPortfolio;
  setActiveCostSavings: (costSavings: CostSavings) => void;
};

type ServiceAnalysisDetailsProps = {
  costSavings?: CostSavings;
  serviceAnalysis?: ServiceAnalysis;
};

type ServiceAnalysisListProps = {
  activeServiceAnalysis: Maybe<ServiceAnalysis>;
  costSavings: CostSavings;
  setActiveServiceAnalysis: (serviceAnalysis: ServiceAnalysis) => void;
};
type CostSavingsAnalysesResult = {
  serviceAnalysis?: ServiceAnalysis;
  percentComplete: number | undefined;
};
type CostSavingsAnalysesResults = Array<CostSavingsAnalysesResult>;

/** ======================== Components ==================================== */

const OrgList: React.FC<OrgListProps> = ({
  activeCostSavings,
  portfolio,
  setActiveCostSavings,
}) => {
  const [createDialogOpen, openCreateDialog, closeCreateDialog] = useDialogState();
  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState();
  const costSavings = _.sortBy(portfolio.CostSavings ?? [], 'name');
  return (
    <>
      <Card chipLabel="Organization Analyses" raised>
        <Select
          fullWidth
          onChange={setActiveCostSavings}
          options={costSavings}
          renderOption="name"
          value={activeCostSavings}
          valueOption="id"
        />

        <FabMenu>
          <List.Item onClick={openCreateDialog}>
            <List.Item.Text>New Organization Analysis</List.Item.Text>
          </List.Item>
          <List.Item disabled={!activeCostSavings} onClick={openDeleteDialog}>
            <List.Item.Icon icon="trash" />
            <List.Item.Text>Delete {activeCostSavings?.name}</List.Item.Text>
          </List.Item>
        </FabMenu>
      </Card>
      <CreateOrganizationSavingsDialog
        onClose={closeCreateDialog}
        open={createDialogOpen}
        portfolio={portfolio}
        setActiveCostSavings={setActiveCostSavings}
      />
      <Dialog.Delete
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        title="Delete Organization Analysis"
        message={`Are you sure you want delete ${activeCostSavings?.name} from ${portfolio.name}`}
        onClickDelete={deleteCostSavings}
      />
    </>
  );
  /**=========================== Callbacks ================================= */
  async function deleteCostSavings() {
    await CostSavings.api.destroy(activeCostSavings?.id!);
    setActiveCostSavings(costSavings[0]);
    AMSPortfolio.api.retrieve(portfolio.id, { include: ['cost_savings'] });
  }
};

const ServiceAnalysisDetails: React.FC<ServiceAnalysisDetailsProps> = ({
  costSavings,
  serviceAnalysis: serviceAnalysisNoData,
}) => {
  const { serviceAnalysis } = useServiceAnalysis(serviceAnalysisNoData?.id, {
    include: [
      'cost_savings',
      'service_periods.service_simulations.service_period',
      'analysis_periods.service_agreement.bundled_rate_plan.friendly_name',
      'analysis_periods.service_agreement.bundled_variations.*',
      'analysis_periods.service_agreement.bundled_variations.rate_type_variations.*',
      'analysis_periods.service_agreement.connection_level.*',
      'analysis_periods.service_agreement.generation_rate_plan.friendly_name',
    ],
  });
  const [downloadURL, setDownloadURL] = React.useState<string>();
  const [waiting, setWaiting] = React.useState<boolean>(false);
  const [collectDataDialogOpen, openCollectDataDialog, closeCollectDataDialog] = useDialogState();
  const [
    serviceAnalysisAnalyzeResults,
    setServiceAnalysisAnalyzeResults,
  ] = React.useState<ServiceAnalysis.API.AnalyzeResult>();
  const [
    costSavingsAnalyzeResults,
    setCostSavingsAnalyzeResults,
  ] = React.useState<CostSavingsAnalysesResults>();

  if (!costSavings || !serviceAnalysis) {
    return <Typography variant="h5">Select Analysis</Typography>;
  }

  const unlocked = !serviceAnalysis.actual_usage;

  return (
    <>
      <Grid justify="center">
        <Grid.Item>
          <Button disabled={waiting} onClick={openCollectDataDialog} size="small" color="primary">
            Collect default data
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button disabled={waiting} onClick={analyzeCostSavings} size="small" color="primary">
            Analyze All
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Divider orientation="vertical" />
        </Grid.Item>
        <Grid.Item>
          <Button
            disabled={waiting}
            onClick={downloadCostSavingsReport}
            size="small"
            color="secondary"
            icon="download"
          >
            Download Report
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            disabled={waiting}
            onClick={downloadCostSavingsGraphs}
            size="small"
            color="secondary"
            icon="download"
          >
            Download Graphs
          </Button>
        </Grid.Item>
      </Grid>
      <Grid justify="center">
        <Grid.Item>
          <Button
            disabled={waiting || unlocked}
            onClick={analyzeServiceAnalysis}
            size="small"
            color="primary"
          >
            Analyze {serviceAnalysis.name}
          </Button>
        </Grid.Item>
      </Grid>
      <Grid justify="center">
        {downloadURL && (
          <Grid.Item>
            <DownloadLink url={downloadURL} />
          </Grid.Item>
        )}
        <Grid.Item span={12}>
          {waiting && <Progress />}
          {!!serviceAnalysisAnalyzeResults && (
            <Card>
              <Grid justify="flex-end">
                <Grid.Item>
                  <Button
                    icon="close"
                    onClick={() => setServiceAnalysisAnalyzeResults(undefined)}
                  />
                </Grid.Item>
              </Grid>
              {serviceAnalysisAnalyzeResults.map(({ analysisPeriod, servicePeriod, result }) => (
                <Grid justify="center" key={`${analysisPeriod?.id}-${servicePeriod?.id}`}>
                  <Grid.Item span={3}>{analysisPeriod?.ServiceAgreement?.name}</Grid.Item>
                  <Grid.Item span={3}>
                    {formatters.date.standard(servicePeriod?.start_date!)} -{' '}
                    {formatters.date.standard(servicePeriod?.end_date!)}
                  </Grid.Item>
                  <Grid.Item span={3}>
                    {result === true ? (
                      'Success'
                    ) : (
                      <Tooltip placement="top" title={(result as ErrorMessage).exc_message[0]}>
                        <Typography color="error">{(result as ErrorMessage).exc_type}</Typography>
                      </Tooltip>
                    )}
                  </Grid.Item>
                </Grid>
              ))}
            </Card>
          )}
          {!!costSavingsAnalyzeResults && (
            <Card>
              <Grid justify="flex-end">
                <Grid.Item>
                  <Button icon="close" onClick={() => setCostSavingsAnalyzeResults(undefined)} />
                </Grid.Item>
              </Grid>
              {_.sortBy(costSavingsAnalyzeResults, (entry) => entry.serviceAnalysis?.name).map(
                ({ serviceAnalysis, percentComplete }) => (
                  <Grid justify="center" key={serviceAnalysis?.id}>
                    <Grid.Item span={4}>{serviceAnalysis?.name}</Grid.Item>
                    <Grid.Item span={4}>
                      {percentComplete === undefined ? (
                        <Progress />
                      ) : (
                        `${Math.round(percentComplete * 100)}% Successful`
                      )}
                    </Grid.Item>
                  </Grid>
                )
              )}
            </Card>
          )}
        </Grid.Item>
        <Grid.Item span={12}>
          <DataPanel analysis={serviceAnalysis} />
        </Grid.Item>
        <Grid.Item span={12}>
          <Box marginTop={3}>
            <ServiceAgreementTabs serviceAnalysis={serviceAnalysis} />
          </Box>
        </Grid.Item>
      </Grid>
      <CollectDefaultOrgDataDialog
        open={collectDataDialogOpen}
        onClose={closeCollectDataDialog}
        costSavings={costSavings}
      />
    </>
  );
  /**================================== Callbacks ========================== */
  async function analyzeServiceAnalysis() {
    if (serviceAnalysis) {
      setWaiting(true);
      setServiceAnalysisAnalyzeResults(undefined);
      const resultList = await ServiceAnalysis.api.analyze(serviceAnalysis?.id!);
      setServiceAnalysisAnalyzeResults(resultList);
      setWaiting(false);
      ServiceAnalysis.api.retrieve(serviceAnalysis?.id!, {
        include: ['service_periods.service_simulations.service_period'],
      });
    }
  }

  async function analyzeCostSavings() {
    setWaiting(true);
    setCostSavingsAnalyzeResults(undefined);
    const resultList = await CostSavings.api.analyze(costSavings?.id!);
    setCostSavingsAnalyzeResults(
      resultList.map(({ serviceAnalysis }) => ({
        serviceAnalysis,
        percentComplete: undefined,
      }))
    );
    resultList.forEach(({ serviceAnalysis, task }) => {
      ServiceAnalysis.api.waitForAnalyses(task?.task_id!).then((analysisList) => {
        const successes = _.filter(analysisList, { result: true }).length;
        const total = analysisList.length;
        setCostSavingsAnalyzeResults((e) => {
          let entry = _.find(e, { serviceAnalysis });
          if (entry) {
            if (total > 0) entry['percentComplete'] = successes / total;
            else entry['percentComplete'] = 0;
            _.remove(e!, { serviceAnalysis });
            return e?.concat(entry);
          }
          return e;
        });
        ServiceAnalysis.api.retrieve(serviceAnalysis?.id!, {
          include: ['service_periods.service_simulations.service_period'],
        });
      });
    });
    setWaiting(false);
  }

  async function downloadCostSavingsReport() {
    setWaiting(true);
    const { task } = await CostSavings.api.downloadReport(costSavings?.id!);
    task.waitForTask((task) => {
      if (task.status === 'SUCCESS') {
        setDownloadURL(task.result);
      }
      setWaiting(false);
    });
  }

  async function downloadCostSavingsGraphs() {
    setWaiting(true);
    const { task } = await CostSavings.api.downloadGraphs(costSavings?.id!);
    task.waitForTask((task) => {
      if (task.status === 'SUCCESS') {
        setDownloadURL(task.result);
      }
      setWaiting(false);
    });
  }
};

const ServiceAnalysisList: React.FC<ServiceAnalysisListProps> = (props) => {
  const { activeServiceAnalysis, costSavings, setActiveServiceAnalysis } = props;
  const [dialogOpen, openDialog, closeDialog] = useDialogState();
  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState();

  // Sort the analyses by their site and service drop names, in that order
  const sorted = React.useMemo(() => _.sortBy(costSavings.ServiceAnalyses, 'name'), [costSavings]);

  React.useEffect(() => {
    if (!activeServiceAnalysis && sorted?.length) setActiveServiceAnalysis(sorted[0]);
  }, [activeServiceAnalysis, setActiveServiceAnalysis, sorted]);
  // Create the list contents
  const listContent = _.iife(() => {
    if (!sorted) {
      return (
        <Box marginTop={1}>
          <Progress />
        </Box>
      );
    } else if (sorted.length === 0) {
      return <Typography>None found</Typography>;
    } else {
      return (
        <List dense>
          {sorted.map((analysis) => (
            <List.Item
              key={analysis.id}
              onClick={() => setActiveServiceAnalysis(analysis)}
              selected={analysis.id === activeServiceAnalysis?.id}
            >
              <Grid justify="space-between">
                <Grid.Item>
                  <List.Item.Text>
                    {analysis.name.length > 26 ? (
                      <Tooltip placement="right-start" title={analysis.name}>
                        <Typography variant="inherit">
                          {formatters.truncateAtLength(analysis.name, 26)}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography noWrap variant="inherit">
                        {analysis.name}
                      </Typography>
                    )}
                  </List.Item.Text>
                </Grid.Item>
                <Grid.Item>
                  <Icon size="small" name={analysis.actual_usage ? 'lock' : 'lock_open'} />
                </Grid.Item>
              </Grid>
            </List.Item>
          ))}
        </List>
      );
    }
  });

  return (
    <>
      <Card chipLabel="Service Drop Analyses" raised padding={10}>
        <Box maxHeight="50vh" overflow="auto" paddingTop="1rem">
          {listContent}
        </Box>

        <FabMenu>
          <List.Item onClick={openDialog}>
            <List.Item.Text>New Service Drop Analysis</List.Item.Text>
          </List.Item>
          <List.Item disabled={!activeServiceAnalysis} onClick={openDeleteDialog}>
            <List.Item.Icon icon="trash" />
            <List.Item.Text>Delete {activeServiceAnalysis?.name}</List.Item.Text>
          </List.Item>
        </FabMenu>
      </Card>

      <CreateServiceDropAnalysisDialog
        onClose={closeDialog}
        open={dialogOpen}
        costSavings={costSavings}
        setActiveServiceAnalysis={setActiveServiceAnalysis}
      />
      <Dialog.Delete
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        title="Delete Service Drop Analysis"
        message={`Are you sure you want delete ${activeServiceAnalysis?.name} from ${costSavings.name}`}
        onClickDelete={deleteServiceAnalysis}
      />
    </>
  );
  /**=========================== Callbacks ================================= */
  async function deleteServiceAnalysis() {
    setActiveServiceAnalysis(sorted[0]);
    ServiceAnalysis.api.destroy(activeServiceAnalysis?.id!);
  }
};

const PortfolioSelect: React.FC<PortfolioSelectProps> = ({ onChange, portfolios, selection }) => {
  const [createDialogOpen, openCreateDialog, closeCreateDialog] = useDialogState();
  const [
    intervalGapReportDialogOpen,
    openIntervalGapReportDialog,
    closeIntervalGapReportDialog,
  ] = useDialogState();
  const [
    billGapReportDialogOpen,
    openBillGapReportDialog,
    closeBillGapReportDialog,
  ] = useDialogState();
  const [collectDataDialogOpen, openCollectDataDialog, closeCollectDataDialog] = useDialogState();

  if (!portfolios) return <Progress />;

  const selectedPortfolio = _.find(portfolios, { id: selection });
  return (
    <>
      <Card chipLabel="Portfolio" raised>
        <Select
          fullWidth
          onChange={onChange}
          options={portfolios}
          renderOption="name"
          value={selectedPortfolio}
        />
        <FabMenu>
          <List.Item onClick={openCreateDialog}>
            <List.Item.Text>New Portfolio</List.Item.Text>
          </List.Item>
          <List.Item onClick={openIntervalGapReportDialog}>
            <List.Item.Text>Download Utility Interval Gap Report</List.Item.Text>
          </List.Item>
          <List.Item onClick={openBillGapReportDialog}>
            <List.Item.Text>Download Utility Bill Gap Report</List.Item.Text>
          </List.Item>
          <List.Item onClick={openCollectDataDialog}>
            <List.Item.Text>Collect Default Data</List.Item.Text>
          </List.Item>
        </FabMenu>
      </Card>
      <IntervalGapReportDialog
        portfolio={selectedPortfolio!}
        open={intervalGapReportDialogOpen}
        onClose={closeIntervalGapReportDialog}
      />
      <BillGapReportDialog
        portfolio={selectedPortfolio!}
        open={billGapReportDialogOpen}
        onClose={closeBillGapReportDialog}
      />
      <CreatePortfolioDialog open={createDialogOpen} onClose={closeCreateDialog} />
      <CollectDefaultPortfolioDataDialog
        open={collectDataDialogOpen}
        onClose={closeCollectDataDialog}
        portfolio={selectedPortfolio!}
      />
    </>
  );
};

export const Analysis: React.FC = () => {
  // State
  const [activePortfolio, setActivePortfolio] = useLocalStorageState('activePortfolio');
  const [activeCostSavings, setActiveCostSavings] = useLocalStorageState('activeCostSavings');
  const [activeServiceAnalysis, setActiveServiceAnalysis] = useLocalStorageState(
    'activeServiceAnalysis'
  );

  const { portfolios } = usePortfolios();
  const { portfolio } = usePortfolio(activePortfolio, { include: 'cost_savings.org.*' });
  const { costSavings } = useCostSavings(activeCostSavings, {
    include: [
      'service_analyses.service_periods.*',
      'service_analyses.service_drop.site.name',
      'service_analyses.analysis_periods.service_agreement.*',
    ],
  });

  const { serviceAnalysis } = useServiceAnalysis(activeServiceAnalysis, {
    include: [
      'cost_savings',
      'service_periods.service_simulations.service_period',
      'analysis_periods.service_agreement.bundled_rate_plan.friendly_name',
      'analysis_periods.service_agreement.bundled_variations.*',
      'analysis_periods.service_agreement.bundled_variations.rate_type_variations.*',
      'analysis_periods.service_agreement.connection_level.*',
      'analysis_periods.service_agreement.generation_rate_plan.friendly_name',
      'service_drop.meters.*',
      'service_drop.demo_meters.*',
      'service_drop.site.*',
      'service_drop.site.org.*',
      'service_drop.solar_generator.monitors.*',
      'service_drop.storage.monitors.*',
    ],
  });

  return (
    <Card raised>
      <Grid>
        <Grid.Item span={3}>
          <Box marginBottom={4} marginTop={1}>
            <PortfolioSelect
              onChange={updatePortfolio}
              portfolios={Object.values(portfolios || {}) as AMSPortfolio[]}
              selection={activePortfolio}
            />
          </Box>
          {!!portfolio && (
            <Box marginBottom={4}>
              <OrgList
                activeCostSavings={costSavings}
                portfolio={portfolio}
                setActiveCostSavings={updateCostSavings}
              />
            </Box>
          )}

          {!!portfolio && !_.isUndefined(costSavings) && (
            <ServiceAnalysisList
              activeServiceAnalysis={serviceAnalysis}
              costSavings={costSavings}
              setActiveServiceAnalysis={updateServiceAnalysis}
            />
          )}
        </Grid.Item>
        <Grid.Item span={9}>
          <Grid>
            <Grid.Item span={12}>
              <Card>
                <Typography variant="h3">
                  {costSavings && (
                    <>
                      <Link to={`/orgs/${costSavings?.org}`}>{costSavings?.name}</Link>
                      {' - '}
                    </>
                  )}
                  <Link
                    to={
                      `/orgs/${costSavings?.org}` +
                      `/site/${serviceAnalysis?.ServiceDrop?.site}` +
                      `/service-drop/${serviceAnalysis?.service_drop}`
                    }
                  >
                    {serviceAnalysis?.name}
                  </Link>
                </Typography>
              </Card>
            </Grid.Item>
            <Grid.Item span={12}>
              <ServiceAnalysisDetails costSavings={costSavings} serviceAnalysis={serviceAnalysis} />
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </Card>
  );

  /** ====================== Callbacks ===================================== */
  function updatePortfolio(portfolio: AMSPortfolio) {
    setActivePortfolio(portfolio.id);
    setActiveCostSavings(undefined);
    setActiveServiceAnalysis(undefined);
  }

  function updateCostSavings(costSavings: CostSavings) {
    setActiveCostSavings(costSavings.id);
    setActiveServiceAnalysis(undefined);
  }

  function updateServiceAnalysis(serviceAnalysis: ServiceAnalysis) {
    setActiveServiceAnalysis(serviceAnalysis.id);
  }
};
