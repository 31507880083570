import _ from 'lodash';
import * as React from 'react';

import {
  Box,
  Button,
  Card,
  Chip,
  FabMenu,
  Flex,
  Grid,
  IFrame,
  List,
  Progress,
  TabDatum,
  Tabs,
  Typography,
  Dialog,
} from 'onescreen/components';
import { useBill, useDialogState } from 'onescreen/hooks';
import {
  AnalysisPeriod,
  Bill,
  ServiceAgreement,
  ServiceAnalysis,
  ServicePeriod,
  ServiceSimulation,
} from 'onescreen/models';
import { makeStylesHook } from 'onescreen/styles';
import { Maybe } from 'onescreen/types';
import { formatters } from 'onescreen/utils';

import { PeriodSelector } from './PeriodSelector';
import { CreateServiceAgreementDialog, SelectServiceAgreementDialog, UpdateServiceAgreementDialog } from './Dialogs';
import { RatePlanVariations } from '../rates/ratePlanVariations';

/** ======================== Types ========================================= */
type BillIFrameProps = { bill?: Bill; analysis?: ServiceAnalysis };
type BillSectionProps = { serviceAgreement: ServiceAgreement; servicePeriod?: ServicePeriod };
type ServiceAgreementTabsProps = { serviceAnalysis: ServiceAnalysis };
type ServiceAgreementTabProps = {
  serviceAgreement: ServiceAgreement;
  serviceAnalysis: ServiceAnalysis;
};

/** ======================== Styles ======================================== */
const useBillIFrameStyles = makeStylesHook(
  () => ({ billViewWrapper: { width: 800 } }),
  'BillIFrame'
);

/** ======================== Components ==================================== */
const BillIFrame: React.FC<BillIFrameProps> = ({ bill, analysis }) => {
  const classes = useBillIFrameStyles();

  // If the bill hasn't loaded, there's nothing to see
  if (!bill) return null;

  const { bill_html } = bill;
  return (
    <Flex.Container>
      {bill_html ? (
        <Flex.Item className={classes.billViewWrapper}>
          <IFrame content={bill.bill_html!} id="bill-frame" title="Bill" />
        </Flex.Item>
      ) : (
        <Progress circular />
      )}

      <Flex.Item>
        <Box marginLeft={1}>
          <Button color="primary" onClick={() => Bill.api.download(bill, analysis)}>
            Download Line Item CSV
          </Button>
        </Box>
      </Flex.Item>
    </Flex.Container>
  );
};

const BillSection: React.FC<BillSectionProps> = ({ serviceAgreement, servicePeriod }) => {
  const serviceSimulation = React.useMemo(
    () => ServiceSimulation.find({ serviceAgreement, servicePeriod }),
    [serviceAgreement, servicePeriod]
  );

  // Load the bill
  const { bill, loading } = useBill(serviceSimulation?.bill!, {
    include: ['bill_html', 'bill_csv'],
  });

  // If no service simulation is found, return null
  if (loading) return <Progress />;

  // If there's no bill ID, render an "Analyze" button
  if (!bill) {
    return null;
  } else {
    return <BillIFrame bill={bill} analysis={servicePeriod?.ServiceAnalysis} />;
  }
};

const ServiceAgreementTab: React.FC<ServiceAgreementTabProps> = (props) => {
  const { serviceAgreement, serviceAnalysis } = props;
  const analysisPeriod = _.find(serviceAnalysis.AnalysisPeriods, {
    service_agreement: serviceAgreement.id,
  });
  const [deleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState();
  const [editDialogOpen, openEditDialog, closeEditDialog] = useDialogState();
  const [servicePeriod, setServicePeriod] = React.useState<Maybe<ServicePeriod>>();

  return (
    <Grid>
      <Grid.Item span={5}>
        <Card padding={10}>
          <TabDatum field="Rate plan" value={serviceAgreement.BundledRatePlan?.friendly_name} />
          {serviceAgreement.generation_rate_plan && (
            <>
              <TabDatum
                field="Generation Rate plan"
                value={formatters.truncateAtLength(
                  serviceAgreement.GenerationRatePlan?.friendly_name,
                  30
                )}
              />
              <TabDatum field="CCA Vintage" value={serviceAgreement.cca_vintage} />
            </>
          )}
          <TabDatum field="Connection level" value={serviceAgreement.ConnectionLevel?.name} />
          <TabDatum
            field="Power factor"
            value={formatters.percentage(serviceAgreement.power_factor, 1)}
          />
        </Card>
      </Grid.Item>
      <Grid.Item span={5}>
        <Box paddingLeft={1} paddingRight={1} boxShadow={0}>
          <RatePlanVariations variations={serviceAgreement.BundledVariations} />
        </Box>
      </Grid.Item>
      <Grid.Item>
        <Button onClick={openEditDialog} size="small" icon="pencil" />
        <UpdateServiceAgreementDialog
          open={editDialogOpen}
          onClose={closeEditDialog}
          serviceAnalysis={serviceAnalysis}
          serviceAgreement={serviceAgreement}
        />
      </Grid.Item>
      <Grid.Item>
        <Button onClick={openDeleteDialog} size="small" icon="trash" />
        <Dialog.Delete
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
          title="Remove Service Agreement"
          message={`Are you sure you want to remove ${serviceAgreement.name || '[No Name]'} from ${
            serviceAnalysis.name
          } analysis?`}
          onClickDelete={removeServiceAgreement}
        />
      </Grid.Item>
      <Grid.Item span={12}>
        <PeriodSelector
          onChange={setServicePeriod}
          selected={servicePeriod}
          serviceAgreement={serviceAgreement}
          serviceAnalysis={serviceAnalysis}
        />
        <Box marginTop={1}>
          <BillSection serviceAgreement={serviceAgreement} servicePeriod={servicePeriod} />
        </Box>
      </Grid.Item>
    </Grid>
  );
  async function removeServiceAgreement() {
    await AnalysisPeriod.api.destroy(analysisPeriod?.id!);
    ServiceAnalysis.api.retrieve(serviceAnalysis.id, {
      include: ['analysis_periods.service_agreement.bundled_variations.rate_type_variations.*'],
    });
  }
};

export const ServiceAgreementTabs: React.FC<ServiceAgreementTabsProps> = ({ serviceAnalysis }) => {
  const analysisPeriods = serviceAnalysis.AnalysisPeriods;
  const [selectDialogOpen, openSelectDialog, closeSelectDialog] = useDialogState();
  const [createDialogOpen, openCreateDialog, closeCreateDialog] = useDialogState();

  return (
    <>
      <Tabs
        cardComponent={
          <Card chipLabel={<Chip color="primary" label="Service Agreements" />} padding={0}>
            <FabMenu>
              <List.Item onClick={openSelectDialog}>
                <List.Item.Text>Add Existing Service Agreement</List.Item.Text>
              </List.Item>
              <List.Item onClick={openCreateDialog}>
                <List.Item.Text>Create New Service Agreement</List.Item.Text>
              </List.Item>
            </FabMenu>
          </Card>
        }
      >
        {analysisPeriods.length
          ? analysisPeriods.map((analysisPeriod) => (
              <Tabs.Tab
                key={analysisPeriod.service_agreement}
                title={analysisPeriod.ServiceAgreement?.name! || '[NO NAME]'}
              >
                <ServiceAgreementTab
                  serviceAgreement={analysisPeriod.ServiceAgreement!}
                  serviceAnalysis={serviceAnalysis}
                />
              </Tabs.Tab>
            ))
          : [
              <Tabs.Tab key="none" title="+">
                <Grid justify="center">
                  <Grid.Item span={12}>
                    <Typography variant="h4">No Service Agreements Yet</Typography>
                  </Grid.Item>
                  <Grid.Item span={4}>
                    <Button onClick={openSelectDialog} size="large" color="primary">
                      Select Existing Service Agreement
                    </Button>
                  </Grid.Item>
                  <Grid.Item span={4}>
                    <Button onClick={openCreateDialog} size="large" color="secondary">
                      Create New Service Agreement
                    </Button>
                  </Grid.Item>
                </Grid>
              </Tabs.Tab>,
            ]}
      </Tabs>
      <SelectServiceAgreementDialog
        open={selectDialogOpen}
        onClose={closeSelectDialog}
        serviceAnalysis={serviceAnalysis}
      />
      <CreateServiceAgreementDialog
        open={createDialogOpen}
        onClose={closeCreateDialog}
        serviceAnalysis={serviceAnalysis}
      />
    </>
  );
};
